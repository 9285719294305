import {createContext, useCallback, useContext, useRef, useState} from "react"
import lang from "../../lang.json"
const Description = createContext()
  
export function DescriptionProvider({ children }) {
    const [state, setState] = useState({ isOpen: false })
    const fn = useRef()

    const confirm = useCallback((data) => { 
      return new Promise((resolve) => {
        setState({ ...data, isOpen: true })
  
        fn.current = (choice) => { 
          resolve(choice) 
          setState({ isOpen: false })
        }

      })
    }, [ setState ])   

    

    return (
        <Description.Provider value={confirm}>
            {children}
            {state.isOpen &&
                <div className="absolute top-0 left-0 z-9999 w-full h-full backdrop-blur-[2.5vw] bg-black/50 flex items-center justify-center">
                    <div className={"lg:w-[40vw] w-[80vw] "+((state.OPTION_IMAGE) ? "lg:h-[70vh]" : "h-auto") +" h-[56.5vh] bg-white rounded-lg border-[.3vh] border-gray-300"}>
                        {state.OPTION_IMAGE &&
                            <div className="w-full lg:h-[45vh] h-[30vh] border-b-[.3vh] border-gray-300">
                                <img src={"https://swiss-dynamics.com/assets/configurator/"+state.type+"/"+state.OPTION_CODE+".webp"} className="w-full h-full rounded-lg object-contain"/>
                            </div>
                        }       
                        <div className="w-full lg:h-[25vh] h-[30vh]">
                            <div className="lg:w-[38vw] w-[76vw] lg:h-[14vh] h-[19vh] mx-[1vw] my-[0.5vh] break-all overflow-auto scrollbar">
                                <h1 className="text-gray-500 text-[1.6vh]">{state.OPTION_PRICE}</h1>
                                <h1 className="text-black font-semibold  text-[1.8vh]">{state.OPTION_TITLE}</h1>
                                <h1 className="text-black text-[1.5vh]" dangerouslySetInnerHTML={{__html: state.OPTION_DESCRIPTION}}></h1>
                            </div>
                            
                            <div className="w-auto mx-[1vw] lg:h-[7vh] h-[5vh] flex gap-[1vw] ">
                                {(!state.isSelected && state.button) &&     
                                    <div className="w-auto h-full bg-black rounded-md flex items-center justify-center" onClick={()=>{
                                        fn.current(true)
                                    }}>
                                        <h1 className="text-white lg:text-[1vw] text-[3vw] px-[1.5vw]">{lang['general'][state.currentLang].lang['LANG_ADDSELECTION']}</h1>
                                    </div>  
                                }
                                {(state.isSelected && state.button) &&     
                                    <div className="w-auto h-full border-[.3vh] border-black rounded-md flex items-center justify-center">
                                        <h1 className="text-black lg:text-[1vw] text-[3vw] px-[1.5vw]">{lang['general'][state.currentLang].lang['LANG_INCLUDEDSELECTION']}</h1>
                                    </div>  
                                }
                               <div className="w-auto h-full border-[.3vh] border-black rounded-md flex items-center justify-center" onClick={()=>{
                                    fn.current(false)
                                }}>
                                    <h1 className="text-black lg:text-[1vw] text-[3vw] px-[1.5vw]">{lang['general'][state.currentLang].lang['LANG_CLOSE']}</h1>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Description.Provider>
    )
}

 
  
  export default function useConfirmDescription() {
    return useContext(Description)
  }