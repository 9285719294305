import { useEffect, useState,useRef } from "react";


import React from 'react';
import { useSearchParams } from 'react-router-dom'


import useConfirm from './components/ConfirmNewSelect/ConfirmNewSelect.jsx';
import useConfirmDescription from './components/Description/Description.jsx';
import QRCode from "./components/qrcode/index.js";

import Notify from "./components/Notification/Notification.jsx";

import axios from 'axios';
import { saveAs } from 'file-saver';


import lang from "./lang.json"
import Header from "./components/Header/Header.jsx";



const perspective = {
  "ikon": [
    "front.webp",
    "side_front.webp",
    "side.webp",
    "side_back.webp",
    "back.webp",
    "top.webp"

  ],
  "fx": [
    "front.webp",
    "side_front.webp",
    "side.webp",
    "side_back.webp",
    "back.webp",
    "top.webp"

  ],
  "mini-ikon": [
    "front.webp",
    "side_front.webp",
    "side.webp",
    "side_back.webp",
    "back.webp"
 
  ]
}

const noEquips = [5,7,8] 
const isDisableIkon = false;
const isDisableFx = false;
const isDisableMiniIkon = false;

function App() {
  const confirmNewSelect = useConfirm();
  const confirmDescription = useConfirmDescription()
  const [searchParams, setSearchParams] = useSearchParams();
  const notification = Notify();

  const formatPrice = (price) => parseInt(price).toLocaleString("en-US", { style: "currency", currency: "CHF", maximumFractionDigits: 0 });

  const [waitTimer,setWaitTimer] = useState(false)
  const [imageFullScreen,setImageFullScreen] = useState(false)
  const [openCategory,setOpenCategory] = useState([])
  const [selectedOptions,setSelectedOptions] = useState([])
  const [selectedImage,setSelectedImage] = useState([]);
  const [currentLang,setCurrentLang] = useState('en');
  const [type,setType] = useState('');
  const newUrl = "101.201.301.401.500.601.700.800.901" // Standard Equip
  const [contactbox,openContactBox] = useState(false)
  const mainContentRef = useRef(null);
  const [seconddaryPage,setSeconddaryPage] = useState(false)
  
  const website = "https://swiss-dynamics.com/"
  const mainLink = "https://configurator.swiss-dynamics.com"
  const apiLink = "https://api-configurator.swiss-dynamics.com"

 


  useEffect(()=>{ 

    // set type/lang

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathname = url.pathname;
    const pathParts = pathname.split("/").filter(part => part !== "");
    if (!pathParts[0])  {
      sendToLink("website")
    }

    if (!pathParts[1])  {
      sendToLink("website")
    }

    if (pathParts[0] === "en" || pathParts[0] === "de" || pathParts[0] === "fr") {
      setCurrentLang(pathParts[0])
    } else {
      sendToLink("website")
    }

  

    if (pathParts[1] === "ikon" || pathParts[1] === "fx" || pathParts[1] === "mini-ikon") {

      if (pathParts[1] === "ikon" && !isDisableIkon) { 
        setType(pathParts[1])
      } else if (pathParts[1] === "fx" && !isDisableFx) { 
        setType(pathParts[1])
      } else if (pathParts[1] === "mini-ikon" && !isDisableMiniIkon) { 
        setType(pathParts[1])
      } else {
        sendToLink("website")
      }
  

    
    } else {
      sendToLink("website")
    }



    // Scroll Page

    const handleScroll = () => {
      const mainContent = mainContentRef.current;
      if (mainContent) {
        const isAtBottom = (mainContent.scrollTop + mainContent.clientHeight >= mainContent.scrollHeight) || (mainContent.scrollHeight - (mainContent.scrollTop + mainContent.clientHeight)) < 3;
        if (isAtBottom) {
          setSeconddaryPage(true)
        } else {
          setSeconddaryPage(false)
        }
      }
    };

 
    const mainContent = mainContentRef.current;
    if (mainContent) {
      mainContent.addEventListener('scroll', handleScroll);
      return () => {
        mainContent.removeEventListener('scroll', handleScroll);
      };
    }
    
   },[])

  useEffect(()=>{
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathname = url.pathname;
    const pathParts = pathname.split("/").filter(part => part !== "");
    const allOptions = searchParams.get('options');

    // rederict link / check options
    if (pathParts[0] === 'ikon-code') {
      if (pathParts[1]) {
        toLink(pathParts[1],'ikon')
      } else {
        sendToLink(mainLink+'/'+currentLang+'/ikon/')
      }
    } else if (pathParts[0] === 'fx-code') { 
      if (pathParts[1]) {
        toLink(pathParts[1],'fx')
      } else {
        sendToLink(mainLink+'/'+currentLang+'/fx/')
      }
    } else if (pathParts[0] === 'mini-ikon-code') { 
      if (pathParts[1]) {
        toLink(pathParts[1],'mini-ikon')
      } else {
     
        sendToLink(mainLink+'/'+currentLang+'/mini-ikon/')
      }
    } else {

      // check options

      if (!allOptions) {
        resetLink(1)
      }


      if (!type) {
        return
      }
      
      if (allOptions && allOptions.length > 0) {
        const data = allOptions.split(".")

        if (data) {
          
          if (checkModifyLink(data)) { // check vaccum / direct measure
            resetLink(2)
          }


          const a = parseInt(data[1].substring(2, 3)) - 1;

          if (!lang[pathParts[1]]) {
            return
          }

          if (!checkIncludes(data,lang[type][currentLang].allOptions[1].CATEGORY_OPTIONS[a].OPTION_INCLUDES)) { // check spindel
            resetLink(3)
          } else {

            const a = parseInt(data[2].substring(2, 3)) - 1;
            if (!checkIncludes(data,lang[type][currentLang].allOptions[2].CATEGORY_OPTIONS[a].OPTION_INCLUDES)) { // check tool
              resetLink(4)
            } else {
              if (!data.includes("700")) {
                const a = parseInt(data[6].substring(2, 3)) - 1;
                if (!checkIncludes(data,lang[type][currentLang].allOptions[6].CATEGORY_OPTIONS[a].OPTION_INCLUDES)) { // check chuck
                  resetLink(5)
                } else {
                  setSelectedOptions(data)
                }
              } else {     
                setSelectedOptions(data)
              }
            }
          }
        }
    

      }
    }




    //Register

    const [navigation] = performance.getEntriesByType('navigation');
    const isFirstLoad = sessionStorage.getItem('isFirstLoad');

 
    if (!isFirstLoad) {
      if (navigation && navigation.type === 'navigate') {

        sessionStorage.setItem('isFirstLoad', 'true');
        fetchRegister()
        console.log('Swiss Dynamics Configurator - '+type.toUpperCase())
      }
    }
  
    if(selectedImage.length == 0) {
      changePerspective(0)
    }

  
  

  },[type])


 
  


  function sendToLink(link) {
    if (link == "website") {
      window.location.href = website
    } else {
      window.location.href = link
    }
  }

  function resetLink(errorNr) {
    console.log('Reset Link: '+ errorNr)
    setSearchParams({options: newUrl}); 
    const data = newUrl.split(".")
    if (data) {
      setSelectedOptions(data)
    }
    
  }

  function checkModifyLink(data) {
    let problemEncountered = false;
    const a = parseInt(data[0].substring(2, 3));
    if (data[4] !== '500') {
      const b = parseInt(data[4].substring(2, 3));
      if (a !== b) {
        problemEncountered = true;
      }
    }
    if (data[7] !== '800') {
      const b = parseInt(data[7].substring(2, 3));
      if (a !== b) {
        problemEncountered = true;
      }
    }
    return problemEncountered;
  }

  function generateCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };


  function setLang(lang) {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathname = url.pathname;
    const allOptions = searchParams.get('options');
    sendToLink(mainLink+'/'+lang+'/'+type+'/?options='+allOptions)
  }

  async function toLink(code,type) {
    try {
      const result = await axios.get(apiLink+'/code/'+type+'/'+code, {});
      
      if (!result) {
        sendToLink(mainLink+'/'+currentLang+'/'+type+'/')
        return
      }
      if (!result.data) {
        sendToLink(mainLink+'/'+currentLang+'/'+type+'/')
        return
      }
      if (result.data == "invalid") {
        sendToLink(mainLink+'/'+currentLang+'/'+type+'/')
      } else {
        sendToLink(mainLink+'/'+currentLang+'/'+type+"/?options=" + result.data)
      }
    
    } catch (error) {
      console.error('Error:', error);
    }
  }
 


  async function fetchRegister() {
   
    try {
      const res = await axios.get("https://freeipapi.com/api/json/");
      
      await axios.post(apiLink+'/register', {
        ip: res.data.ipAddress,
        location: res.data.countryName+" ("+res.data.cityName+") ("+res.data.regionName+")",
      
        type: type,
        currentLang:currentLang
      });
     
    } catch (error) {
  
      console.error('Error:', error);
   
    }
  }

  function changePerspective(index) {
    if (!perspective[type]) {return}
    if (!perspective[type][index]) {return}
    setSelectedImage(perspective[type][index])
  }



  const firstName = useRef(null);
  const lastName = useRef(null);
  const company = useRef(null);
  const location = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const message = useRef(null);

  async function handleSend(e) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d{10}$/;
    if ((firstName.current.value.length == 0) || (lastName.current.value.length == 0) || (company.current.value.length == 0) || (location.current.value.length == 0) || (email.current.value.length == 0) || (phone.current.value.length == 0) || (message.current.value.length == 0)) {
      window.scrollTo(0,0)
      return 
    }

    if (!emailRegex.test(email.current.value.trim())) {
      window.scrollTo(0,0)
      return;
    }

    if (!phoneNumberRegex.test(phone.current.value.trim())) {
      window.scrollTo(0,0)
      return;
    }
    const allOptions = searchParams.get('options');
    try {
    
      await axios.post(apiLink+'/send-email', {
        text:"Type: "+type.toUpperCase()+" <br> FirstName: "+firstName.current.value+" <br> LastName: "+lastName.current.value+" <br> Company: "+company.current.value+" <br> Location: "+location.current.value+" <br> Email: "+email.current.value+" <br> Phone: "+phone.current.value+" <br> Message: "+message.current.value+" <br> Configuration: <a href='"+mainLink+"/"+type+"/?options="+allOptions+"'>Link</a>"
      });
     
 
    } catch (error) {
      console.error('Error:', error);
    } finally {
      openContactBox(false)
      window.scrollTo(0,0)
      notification({message:"Request has been sent successfully!",timeShow:5})
    }





  }

  function checkIsPosibileToSelect(code,options) {
    let IsPosibileToSelect = false;
   
    for (var i = 0; i < options.length; i++) {
      if (options[i] == code) {
        IsPosibileToSelect = true;
        break
      }
      
    }
    return IsPosibileToSelect
  }

  function checkAlreadySelected(option,data) {
    let AlreadySelected = false;
    for (var i = 0; i < data.length; i++) {
      if (option == data[i]) {
        AlreadySelected = true;
        break
      }
    } 
    return AlreadySelected
  }


  function checkHasSelected(option,data) {
    let hasSelectedCategory = false;
    const category = option.substring(0, 1);
    for (var i = 0; i < data.length; i++) {
      const a = data[i].substring(0, 1);
      if (a == category) {
        hasSelectedCategory = i;
        break
      }
      
    }
    return hasSelectedCategory
  }

  function isPosbileNoEquip(option) {
    let isPosible = false
    const a = option.substring(0, 1);
    for (var i = 0; i < noEquips.length; i++) {
      if (noEquips[i] == a) {
        isPosible = true
        break
      }
    }
    return isPosible
  }

  function checkIncludes(data,includesData) {
    let isInclud = false;
    if (includesData && includesData.length > 0) {
      for (var i = 0; i < data.length; i++) {
        for (var k = 0; k < includesData.length; k++) {
          if (data[i] == includesData[k]) {
            isInclud = true;
          }
        }
      }
    }
    return isInclud
  }

  function getOptionData(option) {
    
    let tmp = []
    if (!lang[type]) {
      return
    }

    for (var k = 0; k < lang[type][currentLang].allOptions.length; k++) {
      for (var i = 0; i < lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS.length; i++) {
 
        if ((typeof lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i].OPTION_CODE) == "object") {

          for (var _ = 0; _ < lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i].OPTION_CODE.length; _++) {
            if (lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i].OPTION_CODE[_] == option) {
   
          
              const data = lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i]
              tmp = {
                OPTION_CODE: getOptionCode(data),
                OPTION_INCLUDES: data.OPTION_INCLUDES,
                OPTION_DESCRIPTION: getDescription(data),
                OPTION_PRICE: getPrice(data),
                OPTION_IMAGE: data.OPTION_IMAGE,
                OPTION_TITLE: data.OPTION_TITLE,
              }
            }
          }
        } else {
          if (lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i].OPTION_CODE == option) {
            const data = lang[type][currentLang].allOptions[k].CATEGORY_OPTIONS[i]
            tmp = {
              OPTION_CODE: data.OPTION_CODE,
              OPTION_INCLUDES: data.OPTION_INCLUDES,
              OPTION_DESCRIPTION: data.OPTION_DESCRIPTION,
              OPTION_PRICE: getPrice(data),
              OPTION_IMAGE: data.OPTION_IMAGE,
              OPTION_TITLE: data.OPTION_TITLE,
            }
          
          
          
          
          }
        }
      }
    }
      

    return tmp 
  }

  async function selectedOption(option,includes) {
    let newUrl = "";
    
    const currentOption = searchParams.get('options');
    if (!currentOption) {return 0}
    const data = currentOption.split(".")
    
    if ((typeof option) == "object") {
      const a = selectedOptions[0].substring(2, 3);
      for (var i = 0; i < option.length; i++) {
        const b = option[i].substring(2, 3);
        if (a == b) {
          option = option[i]
          break
        }
      }
    }

    const a = parseInt(option.substring(0, 1));


    if (a == 1) {
      const a = option.substring(2, 3);
      changePerspective(0)
      if (data[4] !== "500") {
        data[4] = "50"+a
      }
     
      if (data[7] !== "800") {
        data[7] = "80"+a
      }
    }
    if (a == 2) {
      let removeData = []
      if (data[1]) {
        removeData[removeData.length] = getOptionData(data[1])
      }
      if (data[2]) {
        removeData[removeData.length] = getOptionData(data[2])
      }
      if (data[6] !== "700") {
        removeData[removeData.length] = getOptionData(data[6])
      }




      const optionData = getOptionData(option)
      if (!optionData){ return }

      let newOption = []
      for (var i = 0; i < includes.length; i++) {
        if (includes[i]) {
          newOption[newOption.length] = getOptionData(includes[i])
        }
      } 
      const hasAlerdySelect = checkAlreadySelected(option,data)
  
      if (hasAlerdySelect) {
        return console.log("Already selected!")
      }



      const choice = await confirmNewSelect({ 
        currentLang: currentLang,
        OPTION_TITLE: optionData.OPTION_TITLE,
        OPTION_PRICE: optionData.OPTION_PRICE,
        OPTION_IMAGE: optionData.OPTION_IMAGE,
        type: type,
        OPTION_CODE: getOptionCode(optionData),
        newOption: newOption,
        removeOption: removeData,
        formatPrice: formatPrice,
        getPrice: getPrice,
        getDescription: getDescription,
        confirmDescription: confirmDescription,
        OPTION_DESCRIPTION: optionData.OPTION_DESCRIPTION
      });
   
      if (choice) {
        if (choice.option) {
          data[2] = getOptionCode(choice.option)
          if (data[6] !== "700") {
            data[6] = "700"
          }
        }
      } else {
        return
      }
    }

    if (a == 3) {
      if (!checkIncludes(data,includes)) {
        return console.log("Dosent work!")
      }
    }


    if (a == 7) {
      if (!checkIncludes(data,includes)) {
        return console.log("Dosent work!")
      }
    }

    const hasAlerdySelect = checkHasSelected(option,data)
    if (option !== "903") {
      if ((hasAlerdySelect || hasAlerdySelect == 0) && hasAlerdySelect !== false) {
        if (data[hasAlerdySelect] == option) {
        
          if (!isPosbileNoEquip(option)) {return console.log("Is not possible to unequip!")}
          const a = data[hasAlerdySelect].substring(0,2);
          if (a) {
            data[hasAlerdySelect] = a+"0"
          }
        } else {
          data[hasAlerdySelect] = option
        }
      }
    } else {
      if (data.includes("903")) {
        delete data[data.length - 1]
      } else {
        data[data.length] = option
      }
    }
   
    for (var i = 0; i < data.length; i++) {
      if (data[i]) {
        if (newUrl) {
          newUrl = newUrl+"."+data[i]
        } else {{
          newUrl = data[i]
        }}
      }
    }

    setSearchParams({options: newUrl});
    setSelectedOptions(data)
  }

  function getInfo(info,parameter) {
    if (info[parameter]) {
      if (typeof info[parameter] == "object") {
        if (!selectedOptions[0]) {return}
        const baseNr = selectedOptions[0].substring(2,3);
        if (!baseNr) {return}
        const price = info[parameter][(baseNr-1)]
        if (!price) {return}
        return price
      } else {
        return info[parameter]
      }
    }
  }

  function getDescription(info) {
    return getInfo(info,"OPTION_DESCRIPTION")
  }

  function getPrice(info) {
    return getInfo(info,"OPTION_PRICE")
  }

  function getOptionCode(info) {
    return getInfo(info,"OPTION_CODE")
  }

  function getTotalPrice() {
    let price = 0
    const currentOption = searchParams.get('options');
    if (!currentOption) {return 0}
    const data = currentOption.split(".")
    for (var i = 0; i < data.length; i++) {
      if (data[i])  {
        const info = getOptionData(data[i])
        if (info && info.OPTION_TITLE && info.OPTION_PRICE) {
          price = price + getPrice(info)
        }
      }
    }
    return price
  }

  function getBasePrice() {
    let basePrice = 0
    const currentOption = searchParams.get('options');
    if (!currentOption) {return 0}
    const data = currentOption.split(".")
    for (var i = 0; i < data.length; i++) {
      if (data[i])  {
        const info = getOptionData(data[i])
        if (info && info.OPTION_TITLE && info.OPTION_PRICE) {
          if (i == 0) {
            basePrice = getPrice(info)
          }
        }
      }
    }
    return basePrice
  }

  function getEquipmentPrice() {
    let equipmentPrice = 0
    const currentOption = searchParams.get('options');
    if (!currentOption) {return 0}
    const data = currentOption.split(".")
    for (var i = 0; i < data.length; i++) {
      if (data[i])  {
        const info = getOptionData(data[i])
        if (info && info.OPTION_TITLE && info.OPTION_PRICE) {
          if (i !== 0) {
            equipmentPrice = equipmentPrice + getPrice(info)
          }
        }
      }
    }
    return equipmentPrice
  }

  async function createPdf() {
    let options = []

    if (waitTimer) {
      return
    }


    for (var i = 0; i < selectedOptions.length; i++) {
     
      const info = getOptionData(selectedOptions[i])
    
        
      if (i == 9) {
        options[options.length-1].CATEGORY_OPTIONS[1] = info
      } else {
        options[options.length] = {
          CATEGORY_TITLE: lang[type][currentLang].allOptions[i].CATEGORY_TITLE,
          CATEGORY_OPTIONS: [info]
        }
      }
      
    }
    const activeCode = generateCode()
    const allOptions = searchParams.get('options');


    try {
      setWaitTimer(true);
     
   
      const res = await axios.get("https://freeipapi.com/api/json/");
     
      await axios.post(apiLink+'/create-pdf', {
        currentLang: currentLang,
        configurationCode: activeCode,
        allOptions:allOptions,
        ip: res.data.ipAddress,
        location: res.data.countryName+" ("+res.data.cityName+") ("+res.data.regionName+")",
        options: options,
        type: type
      });
      const pdfResponse = await axios.get(apiLink+'/fetch-pdf', { 
        responseType: 'blob', 
        params: {configurationCode: activeCode,type:type},
        //onDownloadProgress: function(progressEvent) {
            // Update loading progress
         //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
         //   console.log(percentCompleted)
       // }
      });
      
      const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });

      saveAs(pdfBlob, type.toUpperCase()+'.pdf');
  

    } catch (error) {
      await axios.post(apiLink+'/delete-pdf', { configurationCode: activeCode,type:type });
      console.error('Error:', error);
    } finally {
      setWaitTimer(false);
    }
  }

  const handleImageError = (event) => {
    event.target.src = 'https://swiss-dynamics.com/assets/noimg.webp';
  };

  
  return (
    <div className={"h-[100vh] w-[99.9vw] bg-red "+ (seconddaryPage ? "overflow-auto scrollbar" : "")}>
    {(!imageFullScreen && !contactbox ) && <>
    <div className="h-[100vh] w-full select-none">

    <Header formatPrice={formatPrice} currentLang = {currentLang} type={type} setLang = {setLang} totalPrice = {formatPrice(getTotalPrice())} withPrice = {true}/>


      <div className="w-full h-[88vh] py-[2vh] lg:flex grid gap-[2vw] justify-center ">
        <div className="w-[90vw] lg:w-[70vw] h-full">
          <div className="w-full h-[35vh] lg:h-[75vh] rounded-[1vh] relative">
            <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+selectedOptions[0]+"_"+selectedImage} className="w-full h-full rounded-lg " onError={handleImageError}/>
            <div className="absolute lg:w-[2.5vw] lg:h-[2.5vw] w-[6vw] h-[6vw] bg-white bottom-[2vh] right-[2vh] rounded-md flex items-center justify-center" onClick={()=>{setImageFullScreen(true)}}>
              <svg version="1.1" className="w-[70%] h-[70%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" >
                <path fill="#000000" d="M194.2,54.3h-36.7c-4.1,0-7.4,3.3-7.4,7.4c0,4.1,3.3,7.4,7.4,7.4h19.4l-35.4,35.4l10.4,10.4l35.2-35.2L187,98.5c0,4.1,3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4V61.6c0-2.2-0.9-3.9-2.3-5.2C198.1,55.1,196.2,54.3,194.2,54.3L194.2,54.3z M104.1,141.2l-35.3,35.3l0.2-19c0-4-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4v36.9c0,2.2,0.9,4,2.3,5.2c1.3,1.3,3.2,2.2,5.2,2.2h36.7c4.1,0,7.4-3.3,7.4-7.4c0-4-3.3-7.4-7.4-7.4H79.1l35.4-35.4L104.1,141.2L104.1,141.2z M194.4,150.1c-4.1,0-7.4,3.3-7.4,7.4l0.2,19l-35.3-35.3l-10.4,10.4l35.4,35.4h-19.4c-4.1,0-7.4,3.3-7.4,7.4c0,4,3.3,7.4,7.4,7.4h36.7c2,0,3.9-0.9,5.3-2.2c1.4-1.2,2.3-3,2.3-5.2v-36.9C201.8,153.5,198.4,150.1,194.4,150.1L194.4,150.1z M98.5,69c4.1,0,7.4-3.3,7.4-7.4c0-4.1-3.3-7.4-7.4-7.4H61.8c-2,0-3.9,0.8-5.3,2.2c-1.4,1.2-2.3,3-2.3,5.2v36.9c0,4.1,3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4l-0.2-18.9l35.2,35.2l10.5-10.4L79.1,69H98.5L98.5,69z"/>
              </svg>  
            </div>
          </div>
          <div className="w-auto h-[10vh] my-5 overflow-auto flex flex-wrap lg:flex-nowrap flex-col lg:flex-row gap-[1.5vh] ">
              {perspective[type] && perspective[type].map((data, index) => { 
                if (selectedOptions[0]) {
                  return (
                    <div className={"w-[10vh] lg:w-[8vw] h-full bg-slate-200 rounded-md hover:border-[.3vh] border-black "+((selectedImage == data) ? "border-[.3vh] border-black" : "")} onClick={()=>{changePerspective(index)}} key={"perspective-"+index}>
                      <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+selectedOptions[0]+"_"+data} className="w-full h-full rounded-md" onError={handleImageError}/> 
                    </div>
                  )
                }
              } 
              )}
          
          </div>
        </div>

        <div className={"w-[90vw]  lg:w-[25vw] h-[full] overflow-auto grid gap-[1.5vh] "+(!seconddaryPage ? "scrollbar " : "scrollbar_none ")} ref={mainContentRef}>
          {lang[type] && lang[type][currentLang].allOptions.map((data, index) => {
              return (
                <div className="w-full h-auto bg-white rounded-md px-[1vh] py-[1vh]" key={"options-"+data.CATEGORY_TITLE}>
                  <div className="w-full h-[5vh] flex items-center relative hover:bg-gray-200 hover:rounded-md" onClick={()=>{       
                      setOpenCategory(openCategory=>({
                        ...openCategory,
                      [data.CATEGORY_TITLE]: !openCategory[data.CATEGORY_TITLE] 
                      }))
                    }}>
                    <h1 className="mx-[1vh] text-lg font-bold text-[2vh]">{data.CATEGORY_TITLE}</h1>
                    <div className="absolute w-[2.5vh] h-[2.5vh] right-[1vh] flex items-center justify-center">
                      {openCategory[data.CATEGORY_TITLE] 
                        ? <svg viewBox="0 0 24 24" width="24px" height="24px"><path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg> 
                        : <svg height="16" width="14" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
                      }
                    </div>
                  </div>
                  {!openCategory[data.CATEGORY_TITLE] &&
                    <div className="w-full h-auto py-[.5vw] grid grid-cols-2 gap-x-[1vw] gap-y-[2vw]">
                
                      {data.CATEGORY_OPTIONS && data.CATEGORY_OPTIONS.length > 0 && data.CATEGORY_OPTIONS.map((v, k) => {
                     

                       
                       
                        //if (selectedOptions[0]  && v) {
                        let OPTION_CODE = getOptionCode(v)
                       // }
                     

                        //if (selectedOptions[0] && v.OPTION_CODE) {
                        //  const a = v.OPTION_CODE.substring(0, 1);
                        //  const b = v.OPTION_CODE.substring(2, 3);
                        //  if (parseInt(b) == 1 && parseInt(a) !== 1 && !noEquips.includes(parseInt(a))) {
                        //    isStandard = true;
                        //  }
                       //
                        //}
                        //<div className="w-auto lg:max-w-[9vw] max-w-[30vw] h-auto rounded-lg my-[1vh] mx-[0.5vh] bg-green-300/70 flex items-center justify-center">
                        //  <h1 className="text-black text-[1.3vh] p-[0.5vh]">Standard equipment</h1>
                        //</div>
                        
                        if (v.OPTION_IMAGE) {
                          if ((data.CATEGORY_TITLE == "Collet chuck") || (data.CATEGORY_TITLE == "Spannzangenfutter") || (data.CATEGORY_TITLE == "Mandrin à pince")) {
                            if (checkIsPosibileToSelect(selectedOptions[1],v.OPTION_INCLUDES)) {
                              return(
                                <div className="lg:w-[11.5vw] w-[40vw] h-[62vw] lg:h-[15vw] relative " key={"option-"+k}>
                                
                                  
                                  <div className={"lg:w-[11.5vw] lg:h-[11.5vw]  w-[40vw] h-[40vw] rounded-lg hover:border-[.3vh] hover:border-black "+((selectedOptions.includes(OPTION_CODE)) ? "border-[.3vh] border-[red]" : "border-[.3vh] border-gray-300")} onClick={()=>{selectedOption(getOptionCode(v),v.OPTION_INCLUDES)}} >
                                    <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+OPTION_CODE+".webp"} className="w-full h-full rounded-lg " onError={handleImageError}/>
                                  </div>
                            
                                  
                                  <div className="lg:w-[9.3vw] lg:h-[3.5vw] w-[30vw] h-[3.5vw]">
                                    <h1 className="text-black text-[1.8vh]">{v.OPTION_TITLE}</h1>
                                    
                                      <h1 className="text-gray-500 text-[1.6vh]">{formatPrice(getPrice(v))}</h1>
                                    
                                  </div>
                                  {(getDescription(v) !== "") &&
                                    <div className="absolute w-[2vh] h-[2vh] right-[1vh] lg:top-[12vw] top-[42vw]" onClick={async (event)=>{
                                      event.stopPropagation();
                                      window.scrollTo(0, 0) 
    
                                      const choice = await confirmDescription({ 
                                        currentLang: currentLang,
                                        OPTION_TITLE: v.OPTION_TITLE,
                                        OPTION_DESCRIPTION: getDescription(v),
                                        OPTION_CODE: OPTION_CODE,
                                        OPTION_IMAGE: v.OPTION_IMAGE,
                                        type: type,
                                        isSelected: (selectedOptions.includes(OPTION_CODE)),
                                        button: true,
                                        OPTION_PRICE: formatPrice(getPrice(v))
                                      });
                                      if (choice) {
                                        selectedOption(OPTION_CODE,v.OPTION_INCLUDES)
                                      }
    
                                    }}>
                                      <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                                    </div>
                                  }
                                </div>
                              )
                            }
                          }else {
                            return(
                              <div className="lg:w-[11.5vw] w-[40vw] h-[62vw] lg:h-[15vw] relative " key={"option-"+k}>
                              
                                
                                <div className={"lg:w-[11.5vw] lg:h-[11.5vw]  w-[40vw] h-[40vw] rounded-lg hover:border-[.3vh] hover:border-black "+((selectedOptions.includes(OPTION_CODE)) ? "border-[.3vh] border-[red]" : "border-[.3vh] border-gray-300")} onClick={()=>{selectedOption(getOptionCode(v),v.OPTION_INCLUDES)}} >
                                  <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+OPTION_CODE+".webp"} className="w-full h-full rounded-lg " onError={handleImageError}/>
                                </div>
                          
                                
                                <div className="lg:w-[9.3vw] lg:h-[3.5vw] w-[30vw] h-[3.5vw]">
                                  <h1 className="text-black text-[1.8vh]">{v.OPTION_TITLE}</h1>
                                  
                                    <h1 className="text-gray-500 text-[1.6vh]">{formatPrice(getPrice(v))}</h1>
                                  
                                </div>
                                {(getDescription(v) !== "") &&
                                  <div className="absolute w-[2vh] h-[2vh] right-[1vh] lg:top-[12vw] top-[42vw]" onClick={async (event)=>{
                                    event.stopPropagation();
                                    window.scrollTo(0, 0) 
  
                                    const choice = await confirmDescription({ 
                                      currentLang: currentLang,
                                      OPTION_TITLE: v.OPTION_TITLE,
                                      OPTION_DESCRIPTION: getDescription(v),
                                      OPTION_CODE: OPTION_CODE,
                                      OPTION_IMAGE: v.OPTION_IMAGE,
                                      type: type,
                                      isSelected: (selectedOptions.includes(OPTION_CODE)),
                                      button: true,
                                      OPTION_PRICE: formatPrice(getPrice(v))
                                    });
                                    if (choice) {
                                      selectedOption(OPTION_CODE,v.OPTION_INCLUDES)
                                    }
  
                                  }}>
                                    <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                                  </div>
                                }
                              </div>
                            )
                          }
                          
                        } else {
                          
                             
                                  
                          
                          if ((data.CATEGORY_TITLE == "Tool Magazine") || (data.CATEGORY_TITLE == "Werkzeugmagazin") || (data.CATEGORY_TITLE == "Magasin d'outils")){

                            if (checkIsPosibileToSelect(selectedOptions[1],v.OPTION_INCLUDES)) {
                              return(
                                <div className={"lg:w-[11.5vw] w-[40vw] h-auto relative rounded-lg  "+((selectedOptions.includes(OPTION_CODE)) ? "border-[.3vh] border-[red]" : "border-[.3vh] border-gray-300")} onClick={()=>{selectedOption(OPTION_CODE,v.OPTION_INCLUDES)}} key={"option-"+k}>
                                  <div className="w-full lg:max-h-[10vh]  h-auto p-[1vh]">
                                    <h1 className="text-black text-[1.8vh]">{v.OPTION_TITLE}</h1>
                                    
                                      <h1 className="text-gray-500 text-[1.6vh]">{formatPrice(getPrice(v))}</h1>
                                    
                                  </div>
                                  
                                  {(getDescription(v) !== "") &&
                                    <div className="absolute w-[2vh] h-[2vh] right-[1vh] bottom-[1vh] " onClick={async (event)=>{
                                      event.stopPropagation();
                                      window.scrollTo(0, 0) 
                                     
                                      const choice = await confirmDescription({ 
                                        currentLang: currentLang,
                                        OPTION_TITLE: v.OPTION_TITLE,
                                        OPTION_DESCRIPTION: getDescription(v),
                                        OPTION_CODE: OPTION_CODE,
                                        OPTION_IMAGE: v.OPTION_IMAGE,
                                        type: type,
                                        isSelected: (selectedOptions.includes(OPTION_CODE)),
                                        button: true,
                                        OPTION_PRICE: formatPrice(getPrice(v))
                                      });
                                      if (choice) {
                                        selectedOption(OPTION_CODE,v.OPTION_INCLUDES)
                                      }
                                    }}>
                                  
                                    <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                                  </div>
                                  }
                                </div>
                              )
                            }
                           
                          } else {

                          return(
                            <div className={"lg:w-[11.5vw] w-[40vw] h-auto relative rounded-lg  "+((selectedOptions.includes(OPTION_CODE)) ? "border-[.3vh] border-[red]" : "border-[.3vh] border-gray-300")} onClick={()=>{selectedOption(OPTION_CODE,v.OPTION_INCLUDES)}} key={"option-"+k}>
                              <div className="w-full lg:max-h-[10vh]  h-auto p-[1vh]">
                                <h1 className="text-black text-[1.8vh]">{v.OPTION_TITLE}</h1>
                                
                                  <h1 className="text-gray-500 text-[1.6vh]">{formatPrice(getPrice(v))}</h1>
                                
                              </div>
                              
                              {(getDescription(v) !== "") &&
                                <div className="absolute w-[2vh] h-[2vh] right-[1vh] bottom-[1vh] " onClick={async (event)=>{
                                  event.stopPropagation();
                                  window.scrollTo(0, 0) 
                                 
                                  const choice = await confirmDescription({ 
                                    currentLang: currentLang,
                                    OPTION_TITLE: v.OPTION_TITLE,
                                    OPTION_DESCRIPTION: getDescription(v),
                                    OPTION_CODE: OPTION_CODE,
                                    OPTION_IMAGE: v.OPTION_IMAGE,
                                    type: type,
                                    isSelected: (selectedOptions.includes(OPTION_CODE)),
                                    button: true,
                                    OPTION_PRICE: formatPrice(getPrice(v))
                                  });
                                  if (choice) {
                                    selectedOption(OPTION_CODE,v.OPTION_INCLUDES)
                                  }
                                }}>
                              
                                <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                              </div>
                              }
                            </div>
                          )
                            }
                        }
                          
                        })}
                    </div>
                  }
                </div>
              )
            } 
          )}
        </div>
      </div>
   
      
      
    </div>
    </>
    }
    
    {imageFullScreen && <>
      <div className="w-full h-[100vh] relative flex items-center bg-slate-200/50">
        <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+selectedOptions[0]+"_"+selectedImage} className="w-full lg:h-full h-[40%] lg:rounded-lg " onError={handleImageError}/>
        <div className="absolute lg:w-[2.5vw] lg:h-[2.5vw] w-[10vw] h-[10vw] bg-white bottom-[2vh] right-[2vh] rounded-md flex items-center justify-center" onClick={()=>{setImageFullScreen(false)}}>
          <svg version="1.1" className="w-[50%] h-[50%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path fill="#000000" d="M21.6,10.3c-2.8,0.5-5.7,2.2-7.9,4.7c-2.6,2.9-3.6,5.5-3.7,9.7c0,6.6-0.7,5.7,27.5,34l25,25l-19.2,0.1l-19.1,0.1l-2.6,1.3c-2.9,1.4-5.3,3.8-6.9,6.9c-0.9,1.7-1.1,2.7-1.1,6.4c0,3.7,0.2,4.7,1.1,6.4c1.6,3,4.1,5.5,6.9,6.9l2.6,1.3l25.6,0.1c28.2,0.1,30.6,0,37.3-2.6c10.4-4,19.3-12.9,23.3-23.3c2.6-6.7,2.8-9.1,2.6-37.3L113,24.4l-1.6-2.9c-1.8-3.2-3.2-4.7-6.7-6.5c-2-1-3-1.2-6.3-1.2c-3.6,0-4.2,0.1-6.8,1.5c-3.2,1.8-4.6,3.2-6.5,6.7l-1.2,2.4l-0.1,19.1l-0.1,19.2l-25-25C37,16.1,33.1,12.5,31,11.5C28,10.2,24.7,9.7,21.6,10.3z"/><path fill="#000000" d="M228.3,10.3c-4.2,0.9-5.4,1.9-30.9,27.4l-25,25l-0.1-19.2l-0.1-19.1l-1.2-2.4c-1.8-3.5-3.2-4.9-6.5-6.7c-2.7-1.4-3.2-1.6-6.8-1.6c-3.2,0-4.3,0.2-6.2,1.2c-3,1.4-5.7,4.2-7.2,7.2l-1.2,2.3l-0.1,25.6c-0.1,28.2,0,30.6,2.6,37.3c4,10.4,12.9,19.3,23.3,23.3c6.7,2.6,9.1,2.8,37.3,2.6l25.6-0.1l2.9-1.6c1.6-0.9,3.4-2.2,4.1-3c6.4-7.6,4.5-18.2-4-22.9l-3-1.7l-19.1-0.1l-19.2-0.1l25-25c22.2-22.3,25.1-25.4,26.2-27.8c1.6-3.4,1.8-7.5,0.5-11.3c-1.2-3.4-5.1-7.4-8.4-8.5C233.6,10.1,230.7,9.8,228.3,10.3z"/><path fill="#000000" d="M24.2,143.5c-10.3,3.3-13.9,15.9-6.8,23.8c1.2,1.3,3.2,2.9,4.7,3.6l2.6,1.3l18.9,0.1l18.9,0.1l-25,25C9.2,225.8,10,224.9,10,231.5c0,4.5,1.5,7.8,4.8,10.8c2.8,2.5,6,3.7,10.1,3.6c6.1,0,5.2,0.7,33.3-27.3l25.3-25.2l0.1,19.2l0.1,19.1l1.7,3c4,7.3,12.5,9.9,19.8,6c3.2-1.7,4.6-3.1,6.4-6.7l1.2-2.4v-27.3c0-27.1,0-27.3-1.1-31.2c-3.9-14.1-14.8-25.1-29-29.1c-3.6-1.1-4.2-1.1-30.3-1.2C31.6,142.9,25.7,143,24.2,143.5z"/><path fill="#000000" d="M178.1,143.1c-0.7,0.1-3,0.6-4.8,1.1c-14.1,3.6-25,14.4-29.2,29.1c-0.9,3.3-1,4.4-1.2,30c-0.1,23.6,0,26.7,0.6,28.9c1.2,3.6,3.6,6.6,7.2,8.5c2.8,1.5,3.3,1.7,6.9,1.7c3.6,0,4.2-0.1,6.8-1.6c3.2-1.8,4.6-3.2,6.5-6.7l1.2-2.4l0.1-19.1l0.1-19.2l25,25c28.2,28.1,27.4,27.5,33.8,27.5c10.8,0,17.9-10.9,13.4-20.7c-1.1-2.4-4-5.5-26.2-27.8l-25-25l18.9-0.1l18.9-0.1l2.6-1.3c11.1-5.5,11.1-21.1,0-26.5l-2.6-1.3l-25.9,0C191.1,143,178.9,143.1,178.1,143.1z"/>
          </svg>  
        </div>
        <div className="absolute bottom-[2vh] left-[2vh] w-[75%] h-[10vh]">
          <div className="w-full h-full overflow-auto scrollbar flex flex-wrap flex-col lg:flex-nowrap lg:flex-row gap-[1.5vh]  ">
            {perspective[type] && perspective[type].map((data, index) => {
              if (selectedOptions[0]) {
                return (
                  <div className={"w-[120px] lg:w-[8vw] h-full bg-slate-200 rounded-md hover:border-[.3vh] hover:border-black "+((selectedImage == data) ? "border-[.3vh] border-black" : "")} onClick={()=>{changePerspective(index)}} key={"perspective-"+index}>
                    <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+selectedOptions[0]+"_"+data} className="w-full  h-full rounded-md" onError={handleImageError}/>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </>
    }
    {(seconddaryPage && !imageFullScreen && !contactbox) && 
    <div className="w-full h-auto bg-white ">
     
      <div className="w-full h-[12vh] flex items-center justify-center">
        <h1 className="text-black font-semibold text-[4vh]">{lang['general'][currentLang].lang['LANG_SUMMARY']}</h1>
      </div>
      
      <div className="mx-[15vw] w-[70vw] h-full grid items-center gap-[2vw]">
        <div className="w-full h-[0.1vh] bg-slate-500"></div>
        {lang[type] && lang[type][currentLang].allOptions.map((data, index) => {
          const a = parseInt(selectedOptions[index].substring(2,3));
          if (a == 0) {return}
    
          const info = getOptionData(selectedOptions[index])
      
          return (
            <React.Fragment key={"category-sec-"+index}>
              <div className="w-full h-auto p-[1vw]">
                <div className="w-full h-[5vh] flex items-center justify-center">
                  <h1 className="text-black font-semibold text-[2vh]">{data.CATEGORY_TITLE}</h1>
                </div>
                <div className="w-full h-auto rounded-lg relative" key={"rmv-"+index}>
                
                  <div className="w-[10vh] h-[10vh]">
                    {info.OPTION_IMAGE &&
                      <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+getOptionCode(info)+".webp"} className="w-full h-full rounded-md border-[0.2vh] border-[red]" onError={handleImageError}/>
                    }
                  </div>
         
                  <div className={"absolute top-[1vw] " +(info.OPTION_IMAGE ? "lg:left-[8vw] left-[25vw]  lg:max-w-[20vw] max-w-[33vw]": "lg:left-[0vw] left-[0vw] lg:max-w-[20vw] max-w-[43vw]")+ " w-auto  h-[6vh] break-all"}>
                  <h1 className="text-black font-normal text-[1.7vh]">{info.OPTION_TITLE}</h1>
                  <h1 className="text-slate-600 font-normal text-[1.4vh]">{formatPrice(parseInt(info.OPTION_PRICE))}</h1>
                  <h1 className="text-slate-600 font-normal text-[1.4vh]">{lang['general'][currentLang].lang['LANG_OPTION_CODE']} {getOptionCode(info)}</h1>
                </div>
                
                {(getDescription(info) !== "") &&
                  <div className="absolute w-[2vh] h-[2vh] right-[1vw] top-[1vw]" onClick={async (event)=>{
                    window.scrollTo(0, 0)
                    event.stopPropagation();
               
                    if (!info) {return}
                    await confirmDescription({ 
                      currentLang: currentLang,
                      OPTION_TITLE: info.OPTION_TITLE,
                      OPTION_DESCRIPTION: getDescription(info),
                      OPTION_CODE: getOptionCode(info),
                      OPTION_IMAGE: info.OPTION_IMAGE,
                      type: type,
                      button: false,
                      OPTION_PRICE: formatPrice(getPrice(info))
                    });
                  
                    }}>
                    <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                  </div>
                }
                {(selectedOptions.includes("903") && index == 8)  && 
                <>
                  <div className="w-full h-auto relative mt-[3vw]">
                    <div className="w-[10vh] h-[10vh]">
                      {info.OPTION_IMAGE &&
                      
                        <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+getOptionCode(lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS)+".webp"} className="w-full h-full rounded-md border-[0.2vh] border-[red]" onError={handleImageError}/>
                      }
                    </div>

                    <div className={"absolute top-[1vw] " +(info.OPTION_IMAGE ? "lg:left-[8vw] left-[25vw]  lg:max-w-[20vw] max-w-[33vw]": "lg:left-[0vw] left-[0vw] lg:max-w-[20vw] max-w-[43vw]")+ " w-auto  h-[6vh] break-all"}>
                      <h1 className="text-black font-normal text-[1.7vh]">{lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS[lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS.length - 1].OPTION_TITLE}</h1>
                      <h1 className="text-slate-600 font-normal text-[1.4vh]">{formatPrice(parseInt(lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS[lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS.length - 1].OPTION_PRICE))}</h1>
                      <h1 className="text-slate-600 font-normal text-[1.4vh]">Option code {getOptionCode(lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS)}</h1>
                    </div>
                    {(getDescription(lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS[lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS.length - 1]) !== "") &&
                      <div className="absolute w-[2vh] h-[2vh] right-[1vw] top-[1vw]" onClick={async (event)=>{
                        window.scrollTo(0, 0)
                        event.stopPropagation();
                     
                        const info = lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS[lang[type][currentLang].allOptions[8].CATEGORY_OPTIONS.length - 1]
                        if (!info) {return}
                        await confirmDescription({ 
                          currentLang: currentLang,
                          OPTION_TITLE: info.OPTION_TITLE,
                          OPTION_DESCRIPTION: getDescription(info),
                          OPTION_CODE: getOptionCode(info),
                          OPTION_IMAGE: info.OPTION_IMAGE,
                          type: type,
                          button: false,
                          OPTION_PRICE: formatPrice(getPrice(info))
                        });
                      
                        }}>
                        <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                      </div>
                    }
                  </div>
                </>
              }

                </div>

              </div>
              <div className="w-full h-[0.1vh] bg-slate-500"></div>
             
            </React.Fragment>
          )
        })}

        <div className="w-full h-[30vh] grid grid-cols-2 ">
          <div className="w-full h-[22vh]  relative lg:flex grid items-center ">
            <div className="absolute top-[15vh] md:top-[0vh] lg:right-[2vw] lg:w-[10vw] lg:h-[10vw] w-[20vw] h-[20vw] bg-black">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={mainLink+'/'+currentLang+'/'+type+"/?options="+searchParams.get('options')}
                viewBox={`0 0 256 256`}
              />
            </div>
            
           
            <div className="absolute top-0 lg:w-[20vw] w-full h-[auto] grid items-center gap-[1vw] justify-center ">
            
          


              <div className="lg:w-[13.5vw] lg:h-[3.5vw] h-[8vw] w-[33vw] flex items-center border-[0.1vh] border-black rounded-lg relative hover:bg-slate-400/50" onClick={()=>createPdf()}>
                <svg className="absolute left-[1vw] lg:w-[1.5vw] lg:h-[1.5vw] w-[3vw] h-[3vw]" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                  <g>
                  	<path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002
                  		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995
                  		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507
                  		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649
                  		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012
                  		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z
                  		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"/>
                  	<path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24
                  		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"/>
                  	<path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24
                  		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"/>
                  	<circle cx="7.9590998" cy="21.8405495" r="2"/>
                  	<circle cx="14.2856998" cy="21.8405495" r="2"/>
                  	<circle cx="20.6121998" cy="21.8405495" r="2"/>
                  	<path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"/>
                  </g>
                </svg>
                <h1 className="absolute lg:left-[3vw] left-[5vw] lg:text-[2vh] text-[1.5vh]">{lang['general'][currentLang].lang['LANG_PRINT']}</h1>
                {waitTimer &&
                  <div className="absolute left-[-8vw] lg:left-auto lg:right-[-4vw] h-full w-[8vw] lg:w-[3.5vw] flex justify-center items-center">
                    <svg version="1.1" className="w-[60%] h-[60%]" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 100">
                     <path fill="#000" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                      c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                          <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="2s" 
                            from="0 50 50"
                            to="360 50 50" 
                            repeatCount="indefinite" />
                      </path>
                     <path fill="#000" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                      c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                          <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="1s" 
                            from="0 50 50"
                            to="-360 50 50" 
                            repeatCount="indefinite" />
                      </path>
                     <path fill="#000" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                      L82,35.7z">
                          <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="2s" 
                            from="0 50 50"
                            to="360 50 50" 
                            repeatCount="indefinite" />
                      </path>
                    </svg>
                  </div>
                }
              </div>




              <div className="lg:w-[13.5vw] lg:h-[3.5vw] h-[8vw] w-[33vw] flex items-center border-[0.1vh] border-black rounded-lg relative hover:bg-slate-400/50">
                <svg  className="absolute left-[1vw] lg:w-[1.5vw] lg:h-[1.5vw] w-[4vw] h-[4vw]" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                  <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
                      v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
                      c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
                      C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
                      C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
                      M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
                      c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
                  <path d="M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
                      c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
                      c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
                      c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
                      c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
                      C21.57,47.451,21.52,47.795,21.42,48.073z"/>
                  <path d="M31.954,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814
                      c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513
                      s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757
                      C32.694,46.355,32.378,45.847,31.954,45.4z M30.758,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957
                      c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244
                      C31.496,49.989,31.25,51.02,30.758,51.73z"/>
                  <polygon points="35.598,54 37.266,54 37.266,49.461 41.477,49.461 41.477,48.34 37.266,48.34 37.266,45.168 41.9,45.168 
                      41.9,43.924 35.598,43.924 	"/>
                  <path d="M38.428,22.961c-0.919,0-2.047,0.12-3.358,0.358c-1.83-1.942-3.74-4.778-5.088-7.562c1.337-5.629,0.668-6.426,0.373-6.802
                      c-0.314-0.4-0.757-1.049-1.261-1.049c-0.211,0-0.787,0.096-1.016,0.172c-0.576,0.192-0.886,0.636-1.134,1.215
                      c-0.707,1.653,0.263,4.471,1.261,6.643c-0.853,3.393-2.284,7.454-3.788,10.75c-3.79,1.736-5.803,3.441-5.985,5.068
                      c-0.066,0.592,0.074,1.461,1.115,2.242c0.285,0.213,0.619,0.326,0.967,0.326h0c0.875,0,1.759-0.67,2.782-2.107
                      c0.746-1.048,1.547-2.477,2.383-4.251c2.678-1.171,5.991-2.229,8.828-2.822c1.58,1.517,2.995,2.285,4.211,2.285
                      c0.896,0,1.664-0.412,2.22-1.191c0.579-0.811,0.711-1.537,0.39-2.16C40.943,23.327,39.994,22.961,38.428,22.961z M20.536,32.634
                      c-0.468-0.359-0.441-0.601-0.431-0.692c0.062-0.556,0.933-1.543,3.07-2.744C21.555,32.19,20.685,32.587,20.536,32.634z
                      M28.736,9.712c0.043-0.014,1.045,1.101,0.096,3.216C27.406,11.469,28.638,9.745,28.736,9.712z M26.669,25.738
                      c1.015-2.419,1.959-5.09,2.674-7.564c1.123,2.018,2.472,3.976,3.822,5.544C31.031,24.219,28.759,24.926,26.669,25.738z
                      M39.57,25.259C39.262,25.69,38.594,25.7,38.36,25.7c-0.533,0-0.732-0.317-1.547-0.944c0.672-0.086,1.306-0.108,1.811-0.108
                      c0.889,0,1.052,0.131,1.175,0.197C39.777,24.916,39.719,25.05,39.57,25.259z"/>  
                </svg>
                <a href={"https://swiss-dynamics.com/"+type.toUpperCase()+"_"+(currentLang.toLocaleUpperCase())+".pdf"} className="absolute lg:left-[3vw] left-[5vw] lg:text-[2vh] text-[1.5vh]">{lang['general'][currentLang].lang['LANG_TEHNICALDATA']}</a>
              </div>
            
              <div className="lg:w-[13.5vw] lg:h-[3.5vw] h-[8vw] w-[33vw] flex items-center border-[0.1vh] border-black rounded-lg relative hover:bg-slate-400/50" onClick={()=>{
                window.scrollTo(0, 0) 
                openContactBox(true)
              }}>
              
                <svg className="absolute left-[1vw] lg:w-[1.5vw] lg:h-[1.5vw] w-[4vw] h-[4vw]" fill="#000000"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 337.559 337.559">
			            <path d="M337.559,67.704v-28.33c0-17.506-14.242-31.748-31.748-31.748h-54.572c-4.932-3.021-10.727-4.765-16.922-4.765H32.5
				C14.58,2.86,0,17.44,0,35.36v266.838c0,17.921,14.58,32.5,32.5,32.5h201.816c6.196,0,11.992-1.745,16.925-4.767h54.569
				c17.506,0,31.748-14.242,31.748-31.748v-28.33c0-9.715-4.391-18.42-11.287-24.248c6.896-5.828,11.287-14.533,11.287-24.248
				v-28.331c0-9.715-4.391-18.42-11.287-24.248c6.896-5.828,11.287-14.533,11.287-24.248V116.2c0-9.715-4.391-18.42-11.287-24.248
				C333.168,86.123,337.559,77.418,337.559,67.704z M251.816,302.198c0,9.649-7.851,17.5-17.5,17.5H32.5
				c-9.649,0-17.5-7.851-17.5-17.5V35.36c0-9.649,7.851-17.5,17.5-17.5h201.816c9.649,0,17.5,7.851,17.5,17.5V302.198z
				 M322.559,298.184c0,9.235-7.513,16.748-16.748,16.748h-41.595c1.673-3.912,2.601-8.216,2.601-12.733v-49.093h38.994
				c9.235,0,16.748,7.513,16.748,16.748V298.184z M322.559,221.357c0,9.235-7.513,16.748-16.748,16.748h-38.994v-61.827h38.994
				c9.235,0,16.748,7.513,16.748,16.748V221.357z M322.559,144.53c0,9.235-7.513,16.748-16.748,16.748h-38.994V99.451h38.994
				c9.235,0,16.748,7.513,16.748,16.748V144.53z M322.559,67.704c0,9.235-7.513,16.748-16.748,16.748h-38.994V35.36
				c0-4.518-0.929-8.822-2.602-12.735h41.596c9.235,0,16.748,7.513,16.748,16.748V67.704z"/>
			            <rect x="40.413" y="230.024" width="185.991" height="15"/>
			            <path d="M66.891,206.201h133.035c2.263,0,4.405-1.021,5.829-2.78c1.424-1.759,1.978-4.066,1.507-6.279
				c-3.595-16.907-13.071-32.176-26.474-43.02c8.782-10.818,13.689-24.438,13.689-38.522c0-33.674-27.396-61.07-61.07-61.07
				s-61.07,27.396-61.07,61.07c0,14.084,4.908,27.704,13.689,38.522c-13.402,10.844-22.878,26.112-26.472,43.02
				c-0.471,2.213,0.083,4.521,1.507,6.279C62.486,205.18,64.628,206.201,66.891,206.201z M101.343,161.584
				c1.988-1.245,3.279-3.35,3.488-5.687c0.209-2.337-0.687-4.637-2.422-6.216c-9.579-8.718-15.072-21.14-15.072-34.081
				c0-25.403,20.667-46.07,46.07-46.07c25.403,0,46.07,20.667,46.07,46.07c0,12.941-5.494,25.363-15.072,34.081
				c-1.735,1.579-2.631,3.879-2.422,6.216c0.209,2.337,1.5,4.441,3.488,5.687c11.154,6.989,19.735,17.49,24.42,29.618H76.923
				C81.608,179.074,90.189,168.571,101.343,161.584z"/>
			            <rect x="63.83" y="259.688" width="139.156" height="15"/>
                </svg>
                <h1 className="absolute lg:left-[3vw] left-[5vw] lg:text-[2vh] text-[1.5vh]" >{lang['general'][currentLang].lang['LANG_CONTACTUS']}</h1>
              </div>

            </div>
          </div>
          <div className="w-full h-full bg-white relative">
            <div className="w-full h-[10vh] grid grid-cols-2 ">
              <div className="w-full h-full grid ">
                <h1 className="text-black font-normal text-[1.7vh]">{lang['general'][currentLang].lang['LANG_BPRICE']}</h1>
                <h1 className="text-black font-normal text-[1.7vh]">{lang['general'][currentLang].lang['LANG_EPRICE']}</h1>
              </div>
              
              <div className="w-full h-full grid justify-end ml-[4vw] md:ml-[0vw]">
                <h1 className="text-black font-normal  text-[1.7vh]">{formatPrice(getBasePrice())}</h1>
                <h1 className="text-black font-normal text-[1.7vh]">{formatPrice(getEquipmentPrice())}</h1>
              </div>
            </div>
            <div className="w-full h-[0.1vh] bg-slate-500"></div>
            <div className="w-full h-[10vh] grid grid-cols-2 mt-[1.5vw] ">
              <div className="w-full h-full grid ">
                <h1 className="text-black font-normal text-[2.2vh]">{lang['general'][currentLang].lang['LANG_TPRICE']}</h1>
                <h1 className="text-black font-normal text-[1.5vh]">{lang['general'][currentLang].lang['LANG_FINANCE']}</h1>
              </div>
              <div className="w-full h-full grid justify-end ml-[4vw] md:ml-[0vw]">
                <h1 className="text-black font-normal text-[2.2vh]">{formatPrice(getTotalPrice())}</h1>
                <h1 className="text-black font-normal text-[2.2vh]">{formatPrice((getTotalPrice()/50.22601707684581))}</h1>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    }
    {contactbox && 
    <div className="w-full h-auto bg-black/5  justify-center relative">

      <Header currentLang = {currentLang} setLang = {setLang} getTotalPrice = {getTotalPrice} withPrice = {false}/>

      <div className="mt-[10vh] md:mt-[20vh] ml-[5vw] md:ml-[12.5vw] w-[90vw] md:w-[75vw] h-auto pb-[5vh] md:pb-[20vh] flex justify-between">
        <div className="w-full md:w-[45vw] h-auto rounded-2xl bg-white p-[2.5vw]">
          <div className="w-full md:w-[40vw] h-full ">
            <div className="w-full h-auto flex justify-center">
              <h1 className="text-black text-[2.5vh] md:text-[3vh] font-semibold">{lang['general'][currentLang].lang['LANG_TITLECONTACT']}</h1>
            </div>

            <div className="w-[60vw] md:w-[20vw] h-auto mt-[5vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_FIRSTNAME"]}</h1>
              <input type="text" ref={firstName} className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required></input>
            </div>
            <div className="w-[60vw] md:w-[20vw] h-auto mt-[2vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_LASTNAME"]}</h1>
              <input type="text" ref={lastName} className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required></input>
            </div>
            <div className="w-[60vw] md:w-[20vw] h-auto mt-[2vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_COMPANY"]}</h1>
              <input  type="text" ref={company}  className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required></input>
            </div>
            <div className="w-[60vw] md:w-[20vw] h-auto mt-[2vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_LOCATION"]}</h1>
              <input  type="text" ref={location} className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required></input>
            </div>
            <div className="w-[60vw] md:w-[20vw] h-auto mt-[2vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_EMAIL"]}</h1>
              <input  type="email" ref={email} className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required></input>
            </div>
            <div className="w-[60vw] md:w-[20vw] h-auto mt-[2vh]">
              <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_PHONE"]}</h1>
              <input  type="tel" ref={phone} pattern="[0-9]{10}" className="bg-transparent border border-gray-300 text-md rounded-md hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline w-full p-[1vh]" required ></input>
            </div>
            <div className="w-full h-auto mt-[3vh] ">
            <h1 className="text-black text-[2.5vh] font-[400]">{lang['general'][currentLang].lang["LANG_MESSAGE"]}</h1>
              <textarea ref={message} rows="1" className="block resize-none p-[1vh] w-full h-[20vh] text-md text-gray-900 bg-white rounded-lg border border-gray-300 hover:border-[red] focus:border-[red] focus:outline-none focus:shadow-outline" placeholder={lang['general'][currentLang].lang["LANG_PLACEHOLDER"]}></textarea>
            </div>
            <button className="w-full md:w-auto h-auto mt-[3vh] bg-black p-[2vh] rounded-xl" onClick={handleSend}>
              <span className="text-white text-[2vh]">{lang['general'][currentLang].lang['LANG_BUTTONSEND']}</span>
            </button>
          </div>
        </div>


        <div className="hidden md:block w-[25vw] h-[50vh] rounded-2xl bg-white">     
          <div className="w-full h-[30vh] rounded-t-2xl">
            <img src={"https://swiss-dynamics.com/assets/configurator/"+type+"/"+selectedOptions[0]+"_front.webp"} className="w-full h-full rounded-t-2xl object-fill" onError={handleImageError}/> 
          </div>
          <div className="w-full h-[20vh] rounded-b-2xl ">
            <h1 className="text-black pt-[3vh] pl-[3vh] font-semibold text-[3.2vh]">{getOptionData(selectedOptions[0]).OPTION_TITLE}</h1>
            <h1 className="text-black pt-[2vh] pl-[3vh] font-semibold text-[2.5vh]">{formatPrice(getTotalPrice())}</h1>
            <h1 className="text-black pl-[3vh] font-normal text-[2vh]">{lang['general'][currentLang].lang['LANG_PRICEVAT']}</h1>
          </div>
        </div>
      </div>
      <div className="absolute top-[15vh] md:top-[22vh] left-[5vw] md:left-[12.5vw] w-auto h-auto p-[0.5vh] rounded-xl flex justify-center items-center hover:bg-slate-300" onClick={()=>{ openContactBox(false)}}>
        <svg className="w-[2vh] h-[2vh]" fill="#000000" height="800px" width="800px" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26.676 26.676" >
	        <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"/>
        </svg>
        <h1 className="ml-[1vh] text-black text-[2vh] font-normal">{lang['general'][currentLang].lang['LANG_BACKCONFINGURATOR']}</h1>
      </div>
    </div>
    }

  </div>
  );
}

export default App;
