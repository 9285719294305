import { useEffect, useState,useRef } from "react";
import React from 'react';

function Header({currentLang,setLang,withPrice,type,totalPrice}) {
    return (
        <div className="h-[10vh] w-full flex items-center justify-center relative">
            <div className="lg:w-[20vw] lg:h-[6vh] w-[50vw] h-[5vh]">
              {type == "ikon" && <img src="https://swiss-dynamics.com/assets/topIkon.webp" className="w-full h-full"/>}
            </div>
            {withPrice && <h1 className="absolute right-[3vw] bottom-0 text-black lg:text-[1.3vw] text-[3vw]  font-small">{totalPrice}</h1>}
            <div className="absolute left-[3vw] top-[3vw] lg:top-[1.5vh] lg:w-[9vw] lg:h-[5vh] w-[17vw] h-[2.5vh] " onClick={()=>{
              window.location.href = "https://swiss-dynamics.com"
            }}>
              <img src="https://swiss-dynamics.com/assets/logoTop.webp" className="w-full h-full"/>
            </div>
            <div className="absolute left-[3vw] bottom-0 lg:w-[6vw] w-[15vw] h-auto flex justify-between ">
              <h1 className={"text-black text-[3vw] lg:text-[1vw] font-semibold hover:text-slate-400 "+((currentLang == "en") ? "text-slate-400" : "text-black")} onClick={()=>setLang("en")}>EN</h1>
              <h1 className={"text-black text-[3vw] lg:text-[1vw] font-semibold hover:text-slate-400 "+((currentLang == "de") ? "text-slate-400" : "text-black")} onClick={()=>setLang("de")}>DE</h1>
              <h1 className={"text-[3vw] lg:text-[1vw] font-semibold hover:text-slate-400 "+((currentLang == "fr") ? "text-slate-400" : "text-black")} onClick={()=>setLang("fr")}>FR</h1>
            </div>
        </div>
    )
}

export default Header