import {createContext, useCallback, useContext, useEffect, useRef, useState} from "react"



const notificationCxt = createContext()



export function NotificationProvider({ children }) {
    const [notificationData,setNotificationData] = useState([])
 
    const NotificationItem = ({ message,timestamp,id,timeShow }) => {
    
   
      
        useEffect(() => {
            const calculateTimeAgo = () => {
              const endDateTime = new Date();
              const differenceInMilliseconds = Math.abs(endDateTime - timestamp);
              const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
              
              if (differenceInSeconds == timeShow) {
                setNotificationData((prev) => {
                    return [
                        ...prev.filter((item, i) => item.id !== id)
                    ]
                })
                
              }
            };
        
            const interval = setInterval(calculateTimeAgo, 1000);
        
            return () => clearInterval(interval); 
        
          }, [timestamp]);
      
        return (
            <div className="absolute right-[2vh] top-[2vh] w-[auto] h-[auto] pt-[1vh] pb-[1vh] pl-[1.5vh] pr-[1.5vh] bg-[rgba(0,0,0,0.4)] backdrop-blur rounded-2xl z-999 flex justify-center items-center">
                <h1 className="text-white text-[2vh]">{message}</h1>
            </div>
        
        )
    };

    const Notify = useCallback((data) => { 
       

        const id = (notificationData.length + 1);
   
        const current_time = new Date()
        setNotificationData([...notificationData,  {
            id: id,
            current_time: current_time,
            message: data.message,
            timeShow:  data.timeShow
    
        }]);
     
    
        
    })

    return (
        <notificationCxt.Provider value={Notify}>
            {children}
            <div className="top-0 right-0 w-[50vw] md:w-[30vw] h-auto absolute z-999" >
              

                {notificationData.map(({ id,message,current_time,timeShow  }) => {
                
                   return (
                    <NotificationItem timeShow = {timeShow} id = {id} timestamp = {current_time} message={message}  key={"notify-#"+id}></NotificationItem>
                   )
                    
                })}
            </div>
        </notificationCxt.Provider>
    )
}

export default function Notify() {
    return useContext(notificationCxt)
}