import {createContext, useCallback, useContext, useEffect, useRef, useState} from "react"
import lang from "../../lang.json"
const ConfirmNewSelect = createContext()
  
export function ConfirmNewSelectProvider({ children }) {
    const [state, setState] = useState({ isOpen: false })
    const [selectedOption,setSelectedOption] = useState([])
    const [newPrice,setNewPrice] = useState(0)
    const [rmvPrice,setRmvPrice] = useState(0)
    const fn = useRef()




    const confirm = useCallback((data) => { 
      return new Promise((resolve) => {
        setState({ ...data, isOpen: true })
  
        fn.current = (choice) => { 
          resolve(choice) 
          setState({ isOpen: false })
        }

      })
    }, [ setState ])  
    
    useEffect(()=>{

      if (state) {
        if (state.newOption) {
          setSelectedOption(state.newOption[0])
        }
        if (state.removeOption) {
          let tmpPrice = 0
          for (var i = 0; i < state.removeOption.length; i++) {
            tmpPrice = tmpPrice + parseInt(state.removeOption[i].OPTION_PRICE)
          } 
          
          setRmvPrice(tmpPrice)
        }
      }
      
    },[state])

    useEffect(()=>{ 
      if (state) {
        if (!state.formatPrice) {return}
      let basePrice = parseInt(state.OPTION_PRICE)
      let str = state.formatPrice(basePrice)
      let tmpPrice = 0


      if (state.newOption && selectedOption.length !== 0) {
        tmpPrice = tmpPrice + parseInt(selectedOption.OPTION_PRICE)
      }
      const a = parseInt(tmpPrice + basePrice)
      if (a > rmvPrice) {
        str = "+"+state.formatPrice(parseInt(a - rmvPrice))
      } else if (a < rmvPrice){
        str = "-"+state.formatPrice(parseInt(rmvPrice - a))
      } else {
        str = "CHF0"
      }
      setNewPrice(str)
      }
    },[selectedOption])

   


    return (
        <ConfirmNewSelect.Provider value={confirm}>
            {children}
            {state.isOpen &&
                <div className="absolute top-0 left-0 z-9999 w-full h-full backdrop-blur-[2.5vw] bg-black/50 flex justify-end">
                    <div className="lg:w-[40vw] w-full h-full bg-white overflow-y-auto overflow-x-hidden scrollbar">
                      <div className="lg:w-[35vw] w-full h-[8vh] mx-[2.5vw] mt-[2.5vw] ">
                        <h1 className="text-black font-semibold text-[2.5vh]">{lang['general'][state.currentLang].lang['LANG_SELECTIONWIZARD']}</h1>
                        <h1 className="text-black font-normal lg:text-[1.4vh] text-[2vh]">{lang['general'][state.currentLang].lang['LANG_SELECTIONWIZARD_DESCRIPTION']}</h1>
                      </div>
                      <div className="lg:w-[35vw] w-[95vw]  h-auto mx-[2.5vw] lg:my-[.5vw] my-[3vh] relative grid gap-[1vh]">
                        <div className="w-full h-auto border-[0.1vh] border-slate-300 rounded-lg relative p-[1vw]">
                          <h1 className=" text-black font-semibold text-[1.6vh]">{lang['general'][state.currentLang].lang['LANG_SELECTIONWIZARD_TITLE']}</h1>
                          <div className="w-[6vh] h-[6vh] rounded-sm flex">
                             {state.OPTION_IMAGE && 
                              <img src={"https://swiss-dynamics.com/assets/configurator/"+state.type+"/"+state.OPTION_CODE+".webp"} className="w-full h-full rounded-md object-contain"/>
                             }
                          </div>

                          <div className={"absolute lg:top-[5.5vh] top-[3.5vh] "+((state.OPTION_IMAGE) ? "lg:left-[5vw] md:left-[3vw] left-[10vw]" : "left-[0vw]")+" w-[70vw] lg:max-w-[20vw]  h-[6vh] break-all"}>
                            <h1 className="text-black font-normal text-[1.7vh]">{state.OPTION_TITLE}</h1>
                            <h1 className="text-slate-600 font-normal text-[1.4vh]">{state.formatPrice(parseInt(state.OPTION_PRICE))}</h1>
                          </div>
                          {(state.getDescription(state) !== "") &&
                            <div className="absolute w-[2vh] h-[2vh] right-[2vw] lg:top-[5.5vh] top-[3.5vh] " onClick={async (event)=>{
                              event.stopPropagation();
                              window.scrollTo(0, 0) 
                              await state.confirmDescription({ 
                                currentLang: state.currentLang,
                                OPTION_TITLE: state.OPTION_TITLE,
                                OPTION_DESCRIPTION: state.getDescription(state),
                                OPTION_CODE: state.OPTION_CODE,
                                OPTION_IMAGE: state.OPTION_IMAGE,
                                type: state.type,
                                button: false,
                                OPTION_PRICE: state.formatPrice(state.getPrice(state))
                              });
                            
                              }}>
                              <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                            </div>
                          }
                        </div>
                      
                        {state.removeOption && 
                          <div className="w-full h-auto border-[0.1vh] border-slate-300 rounded-lg relative p-[1vw]">
                            <div className="w-full h-[3vh] relative flex justify-center items-center ">
                              <h1 className="absolute left-0 text-black text-[1.7vh]">{lang['general'][state.currentLang].lang['LANG_REMOVESELECTION']}</h1>
                              <h1 className="absolute right-0 text-black text-[1.7vh] font-semibold">{state.formatPrice(parseInt(-rmvPrice))}</h1>
                            </div>
                            {state.removeOption.map((data, index) => {  
                              return (
                                <div className="w-full h-auto rounded-lg relative p-[1vw]" key={"rmv-"+index}>
                                 
                                  <div className="w-[6vh] h-[6vh] rounded-sm">
                                     {data.OPTION_IMAGE && 
                                      <img src={"https://swiss-dynamics.com/assets/configurator/"+state.type+"/"+data.OPTION_CODE+".webp"} className="w-full h-full rounded-md object-contain"/>
                                     }
                                  </div>
                                  
                                  <div className={"absolute top-[1vw] "+((data.OPTION_IMAGE) ? "lg:left-[5vw] md:left-[3vw] left-[10vw]" : "left-[0vw]")+" w-[70vw] lg:max-w-[20vw]  h-[6vh] break-all"}>
                                    <h1 className="text-black font-normal text-[1.7vh]">{data.OPTION_TITLE}</h1>
                                    <h1 className="text-slate-600 font-normal text-[1.4vh]">{state.formatPrice(parseInt(data.OPTION_PRICE))}</h1>
                                  </div>
                                  {(state.getDescription(data) !== "") &&
                                    <div className="absolute w-[2vh] h-[2vh] right-[1vw] top-[1vw]" onClick={async (event)=>{
                                      event.stopPropagation();
                                      window.scrollTo(0, 0) 
                                      await state.confirmDescription({ 
                                        currentLang: state.currentLang,
                                        OPTION_TITLE: data.OPTION_TITLE,
                                        OPTION_DESCRIPTION: state.getDescription(data),
                                        OPTION_CODE: data.OPTION_CODE,
                                        OPTION_IMAGE: data.OPTION_IMAGE,
                                        type: state.type,
                                        button: false,
                                        OPTION_PRICE: state.formatPrice(state.getPrice(data))
                                      });
                                    
                                      }}>
                                      <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
                        }  
                        
                        {state.newOption && 
                          <div className="w-full h-auto border-[0.1vh] border-slate-300 rounded-lg relative p-[1vw]">
                            <div className="w-full h-[3vh] relative flex justify-center items-center ">
                              <h1 className="absolute left-0 text-black text-[1.7vh]">{lang['general'][state.currentLang].lang['LANG_SELETABLEOPTION']}</h1>
                              <h1 className="absolute right-0 text-black text-[1.7vh] font-semibold">+{state.formatPrice(parseInt(selectedOption.OPTION_PRICE))}</h1>
                            </div>
                            {state.newOption.map((data, index) => { 
                             
                            return (
                              <div className={"w-full h-auto rounded-lg relative mt-[1vw] p-[.5vw] " + ((selectedOption.OPTION_CODE == data.OPTION_CODE) ? "border-[0.1vh] border-slate-300" : "") } key={"new-"+index} onClick={()=>{
                            
                                  setSelectedOption(data)
                                
                              }}>

                                <div className="lg:w-[7vw] w-[6vh] h-[6vh] rounded-sm" >
                                   {data.OPTION_IMAGE && 
                                    <img src={"https://swiss-dynamics.com/assets/configurator/"+state.type+"/"+data.OPTION_CODE+".webp"} className={"w-full h-full object-contain rounded-md "+((selectedOption.OPTION_CODE == data.OPTION_CODE) ? "border-[.3vh] border-black" : "hover:border-[.3vh] hover:border-black")} />
                                   }
                                  </div>

                                  <div className={"absolute top-[1vw] "+((data.OPTION_IMAGE) ? "lg:left-[5vw] md:left-[3vw] left-[10vw]" : "left-[0vw]")+" w-[70vw] lg:max-w-[20vw]  h-[6vh] break-all"}>
                                  <h1 className="text-black font-normal pl-[1vw] text-[1.7vh]">{data.OPTION_TITLE}</h1>
                                  <h1 className="text-slate-600 font-normal pl-[1vw] text-[1.4vh]">{state.formatPrice(parseInt(data.OPTION_PRICE))}</h1>
                                </div>
                                {(state.getDescription(data) !== "") &&
                                  <div className="absolute w-[2vh] h-[2vh] right-[1vw] top-[1vw]" onClick={async (event)=>{
                                    event.stopPropagation();
                                    window.scrollTo(0, 0) 
                                    await state.confirmDescription({ 
                                      currentLang: state.currentLang,
                                      OPTION_TITLE: data.OPTION_TITLE,
                                      OPTION_DESCRIPTION: state.getDescription(data),
                                      OPTION_CODE: data.OPTION_CODE,
                                      OPTION_IMAGE: data.OPTION_IMAGE,
                                      type: state.type,
                                      button: false,
                                      OPTION_PRICE: state.formatPrice(state.getPrice(data.OPTION_TITLE,data.OPTION_PRICE))
                                    });
                                  
                                    }}>
                                    <svg className="w-full h-full" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/></svg>
                                  </div>
                                }
                              </div>
                            )
                          })}
                          </div>
                        }                       
                      </div>

                      <div className="w-full h-[5vh] relative flex justify-center items-center mt-[5vw] ">
                        <h1 className="absolute left-[2.5vw] text-slate-600 text-[1.5vh]">{lang['general'][state.currentLang].lang['LANG_PRICECHANGE']}</h1>
                        <h1 className="absolute right-[2.5vw] text-black text-[2vh] font-semibold">{newPrice}</h1>
                      </div>
                      <div className="w-auto mx-[2.5vw] h-[6vh] mb-[2.5vw] flex items-center gap-[1vw] mt-[0.5vw]">
                        
                        <div className="w-auto h-full bg-black rounded-md flex items-center justify-center" onClick={()=>{fn.current({option: selectedOption})}}>
                          <h1 className="text-white lg:text-[1vw] text-[3vw] px-[1.5vw]">{lang['general'][state.currentLang].lang['LANG_APPLYCHANGE']}</h1>
                        </div>  
                        
                        
                        <div className="w-auto h-full border-[.3vh] border-black rounded-md flex items-center justify-center" onClick={()=>{fn.current(false)}}>
                          <h1 className="text-black lg:text-[1vw] text-[3vw] px-[1.5vw]">{lang['general'][state.currentLang].lang['LANG_DISCARDCHANGE']}</h1>
                        </div>  
                          
                    </div>
                  </div>
                </div>
            }
        </ConfirmNewSelect.Provider>
    )
}


  
export default function useConfirm() {
  return useContext(ConfirmNewSelect)
}