import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {BrowserRouter as Router} from 'react-router-dom';
import {ConfirmNewSelectProvider} from "./components/ConfirmNewSelect/ConfirmNewSelect.jsx"
import { DescriptionProvider } from './components/Description/Description';

import { NotificationProvider } from './components/Notification/Notification';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <DescriptionProvider>
        <ConfirmNewSelectProvider>
          <Router>
            <App/>
          </Router>
        </ConfirmNewSelectProvider>
      </DescriptionProvider>
    </NotificationProvider>
  </React.StrictMode>
);



